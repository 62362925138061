import React from "react";

const HomeDesc = () => {
  return (
    <section className="desc">
      <img
        className="grid"
        src={require("../../assets/static/pages/index/feature/grid.svg")}
        alt={""}
      />
      <div className="desc__text">
        <h1>Crypto Flow Intel</h1>
        <p>
          Crypto Flow Intel is a streaming analytics system
          <br /> and realtime web app that last year analyzed
          <br /> 3 billion trades, over 1 million news articles plus all
          <br /> the social media that goes with it
        </p>
        <p>
          We use machine learning technology to make the
          <br /> crypto space easy to understand and manage the
          <br /> fluctuating value of the assets.
        </p>
      </div>
      <div className="desc__visual">
        <div className="desc__card">
          <ul>
            <li>
              <p className="no">1</p>
              <img src="http://bit.ly/2RUxGkY" alt="crypto" />
              <p className="name">Bitcoin</p>
              <p className="price">$ 8976.5</p>
              <p className="up red">- 0.22%</p>
              <p className="amount">$ 162,792,874,150</p>
            </li>
            <li>
              <p className="no">2</p>
              <img src="http://bit.ly/3b6LXT5" alt="crypto" />
              <p className="name">Ethereum</p>
              <p className="price">$ 170.65</p>
              <p className="up red">- 0.64%</p>
              <p className="amount">$ 18,679,896,864</p>
            </li>
            <li>
              <p className="no">3</p>
              <img src="http://bit.ly/2RUIMGu" alt="crypto" />
              <p className="name">XRP</p>
              <p className="price">$ 0.233</p>
              <p className="up red">- 0.07%</p>
              <p className="amount">$ 10,198,467,887</p>
            </li>
            <li>
              <p className="no">4</p>
              <img src="http://bit.ly/37Ypt4A" alt="crypto" />
              <p className="name">Tether</p>
              <p className="price">$ 1.001</p>
              <p className="up green">0.05%</p>
              <p className="amount">$ 4,646,081,308</p>
            </li>
            <li>
              <p className="no">5</p>
              <img
                src="https://cdn.freebiesupply.com/logos/large/2x/eos-3-logo-png-transparent.png"
                alt="crypto"
              />
              <p className="name">EOS</p>
              <p className="price">$ 3.915</p>
              <p className="up red">- 2.40%</p>
              <p className="amount">$ 3,739,937,729</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
export default HomeDesc;
