import React from "react";

const HomeTag = () => {
  return (
    <section className="tag">
      <img
        src={require("../../assets/static/pages/index/tag/cfn.svg")}
        alt="cfn"
      />
      <h1>Simplify Markets, Media, and Development Teams</h1>
      <p>
        Data, news & analysis + streaming analytics. Design to manage risk
        <br />
        and unlock the potential benefits of crypto finance.
      </p>
    </section>
  );
};

export default HomeTag;
