import React from "react";

const HomeStory = () => {
  return (
    <section className={"story"}>
      <div className={"story__desc"}>
        <h1>Key Major Coin</h1>
        <div className={"story__scroll"}>
          <p>
            To better understand global market dynamics Key Major Coin designed
            the Crypto Flow Index (CFx). Composed of 16 asset indices that
            aggregate global market dynamics through computational models. These
            assets are leaders in market capitalization and global trading
            volume as well as backed by the best development teams in the world.
            This tool gives our quantitative execution system a clear view of
            the market. When leveraged with proprietary Machine Learning
            Relative Strength (MLRS) analysis profitability becomes clear.
          </p>
          <p>
            Key Major Coin media AI brings forward knowledge from thousands of
            articles daily. Be first informed about new developments surrounding
            the assets tracked by the CFx and review new advancements in
            blockchain technology, adoption and regulation.
          </p>
          <p>
            Enjoy the Crypto Flow Intel Report, our daily service that analyzes
            the crypto market by utilizing CFx Indices and Machine Learning
            Relative Strength. We publish summarized reports on market events
            and social buzz.
          </p>
          <p>
            Crypto Flow Intel is where the Members of Decentralized Networks go
            to find out what’s happening in cryptocurrency news, social media
            and markets. Welcome to the future of finance.
          </p>
        </div>
      </div>
      <div className={"story__visual"}>
        <img
          src={require("../../assets/static/pages/index/banner/robo.svg")}
          alt={""}
        />
      </div>
    </section>
  );
};

export default HomeStory;
