import React from "react";

const Error404 = () => {
  return (
    <div className={"error"}>
      <div className="error__container">
        <img
          src={require("../../assets/static/pages/status/404.svg")}
          alt="404"
        />
        <h1>
          <span>Error 404</span> | Page Not Found
        </h1>
        <p>
          He learned the important lesson that a website at the beach on a windy
          day<br/> is a bad idea. So this page is still under development
        </p>
      </div>
    </div>
  );
};

export default Error404;
