import React, { Component } from 'react'
import { Route }            from 'react-router-dom'
import {TweenMax, Power4}   from 'gsap'

import Navbar               from '../layouts/Navbar'
import Footer               from '../layouts/Footer'

import Home                 from '../../pages/home/Home'
import Error404             from '../../pages/status/Error404'

class App extends Component {
  state = {
    showMenu: false,
    navBackground: 'bg--transparent'
  }

  handleMenu = () => {
    const { showMenu } = this.state
    this.setState({ showMenu: !showMenu })
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const backgroundcolor =
        window.scrollY < 100 ? 'bg--transparent' : 'bg--primary desktop'

      this.setState({ navBackground: backgroundcolor })
    })

    TweenMax.from('.app', 2, {
      x: '-100%',
      opacity: 0,
      ease: Power4.easeOut
    })
  }

  render() {
    const { showMenu, navBackground } = this.state
    return (
      <div className='app'>
        <Navbar show={showMenu} bg={navBackground} toggle={this.handleMenu} />
          <Route path={'/'} component={Home} exact />
          <Route path={'/intelligence'} component={Error404} />
          <Route path={'/media'} component={Error404} />
          <Route path={'/control'} component={Error404} />
          <Route path={'/marketcap'} component={Error404} />
          <Route path={'/weeklyrecap'} component={Error404} />
        <Footer />
      </div>
    )
  }
}

export default App
