import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { TweenMax, Power4 } from "gsap";

const Navbar = ({ show, toggle, bg }) => {
  useEffect(() => {
    TweenMax.from(".navbar", 1, {
      opacity: 0,
      y: "-100%",
      ease: Power4.easeIn
    });
  }, []);

  return (
    <nav className={`navbar ${bg}`}>
      <div className="navbar__logo">
        <Link to={"/"}>
          <img
            src={require("../../assets/static/layouts/navBar/keymajorlogo.svg")}
            alt="keymajorlogo"
          />
        </Link>
      </div>
      <ul className={`navbar__menu ${show ? "show" : "hide"}`} onClick={toggle}>
        <li>
          <a
            target="_blank"
            href="https://bitcoin-blog.keymajorcoin.com/"
            rel="noopener noreferrer"
          >
            intelligence
          </a>
        </li>
        <li>
          <a target="_blank" href="https://media.keymajorcoin.com/" rel="noopener noreferrer">
            Media
          </a>
        </li>
        <li>
          <a target="_blank" href="https://controlpanel.keymajorcoin.com/" rel="noopener noreferrer">
            control panel
          </a>
        </li>
        <li>
          <a target="_blank" href="https://marketcap.keymajorcoin.com/" rel="noopener noreferrer">
            market cap
          </a>
        </li>
        {/* <li>
          <a target="_blank" href="https://cfirecap.keymajorcoin.com/" rel="noopener noreferrer">weekly recap</a>
        </li> */}
      </ul>
      <div className="navbar__icon">
        <i className="fas fa-bars" onClick={toggle} />
      </div>
    </nav>
  );
};

export default Navbar;
