import React, { useEffect } from "react";
import { TweenMax, Power4 } from 'gsap'

const HomeBanner = () => {

  useEffect(() => {
    TweenMax.from('.banner__text h1', 1.8, {
      delay: 2,
      y: '2rem',
      opacity: 0,
      ease: Power4.easeOut
    })
    TweenMax.from('.banner__text p', 1.8, {
      delay: 2.2,
      y: '2rem',
      opacity: 0,
      ease: Power4.easeOut
    })
    TweenMax.from('.banner__text button', 1, {
      delay: 2.4,
      y: '2rem',
      opacity: 0,
      scale: 0,
      ease: Power4.easeIn
    })
    TweenMax.from('.banner__net', 2, {
      delay: 3.5,
      width: 0,
      opacity: 0,
      ease: Power4.easeOut
    })
  }, [])

  return (
    <section className="banner">
      <img
        className="banner__net"
        src={require("../../assets/static/pages/index/banner/network.svg")}
        alt="net"
      />
      <div className="banner__img">
        <img
          className="glow"
          src={require("../../assets/static/pages/index/banner/glow.svg")}
          alt="glow"
        />
        <img
          className="robo"
          src={require("../../assets/static/pages/index/banner/robo.svg")}
          alt="robo"
        />
      </div>
      <div className="banner__text">
        <h1>
          Crypto is Easy <br />
          Now Enjoy the Benefits
        </h1>
        <p>
          Crypto Flow Intel is a realtime web app that streams
          <br /> insider intel 24/7 to your desktop or mobile
        </p>
        <a target="_blank" href="https://controlpanel.keymajorcoin.com"> <button>Key Major Coin</button></a>
       
      </div>
    </section>
  );
};

export default HomeBanner;
