import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__logo">
        <img
          src={require("../../assets/static/layouts/footer/cfnlogo.svg")}
          alt="cfnlogo"
        />
      </div>
      <div className="footer__tag">
        copyright © 2020 Crypto Flow Intel. <br />
        All Rights Reserved.
      </div>
      <div className="footer__icons">
        <a target="_blank" href="https://twitter.com/KeyMajorCoin" rel="noopener noreferrer">
          <i className="fab fa-twitter" />
        </a>
        <a target="_blank" href="https://t.me/KMC_intelReport" rel="noopener noreferrer">
          <i className="fab fa-telegram-plane" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
