import React from "react";
const HomeFeature = () => {

  return (
    <section className="feature">
      <img
        src={require("../../assets/static/pages/index/feature/grid.svg")}
        alt="grid"
        className="grid"
      />
      <img
        src={require("../../assets/static/pages/index/feature/grid.svg")}
        alt="grid"
        className="box"
      />
      <div className="feature__text">
        <h1>Our Technology</h1>
        <p>
          Use the technology stack market makers
          <br/> and hedge funds managers use to take
          <br/> advantage of opportunities and avoid surprises
        </p>
      </div>
      <div className="feature__visual">
        <div className="img">
          <img
            className="financial"
            src={require("../../assets/static/pages/index/feature/financial.png")}
            alt="financial"
          />
          <p>Financial Engineering</p>
        </div>
        <div className="img">
          <img
            className="indicies"
            src={require("../../assets/static/pages/index/feature/indicies.png")}
            alt="indices"
          />
          <p>Crypto Indicies</p>
        </div>
        <div className="img">
          <img
            className="machine"
            src={require("../../assets/static/pages/index/feature/machine.png")}
            alt="machine"
          />
          <p>Machine Learning Tools</p>
        </div>
      </div>
      <div className="feature__button">
        <a target="_blank" href={'https://controlpanel.keymajorcoin.com/'}>
        <button>Key Major Coin</button>
        </a>
      </div>
    </section>
  );
};

export default HomeFeature;
