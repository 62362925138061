import React from "react";
import HomeBanner from "./HomeBanner";
import HomeFeature from "./HomeFeature";
import HomeTag from "./HomeTag";
import HomeDesc from "./HomeDesc";
import HomeStory from "./HomeStory";

const Home = () => {
  return (
    <div className="home">
      <HomeBanner />
      <HomeFeature />
      <HomeTag />
      <HomeDesc />
      <HomeStory />
    </div>
  );
};

export default Home;
